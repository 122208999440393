import { memo } from 'react';
import {
  Box,
  Typography,
  WidgetContainer,
  OldSecurityScoreWidget as SharedSecurityScoreWidget, // TODO: NEXTGEN-13137
  useTranslations,
} from '@uniqkey-frontend/shared-app';

interface IDetailsSecurityScoreWidgetProps {
  isLoading: boolean;
  percentage?: number | null;
}

const SecurityScoreWidget = (props: IDetailsSecurityScoreWidgetProps) => {
  const { isLoading, percentage } = props;
  const { t } = useTranslations();

  return (
    <WidgetContainer withShadow isLoading={isLoading}>
      <Typography variant="subtitle1">{t('common.securityScore')}</Typography>
      <Box p={2} display="flex" justifyContent="center">
        <SharedSecurityScoreWidget percentage={percentage ?? 0} t={t} />
      </Box>
    </WidgetContainer>
  );
};

export default memo(SecurityScoreWidget);
